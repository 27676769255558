var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TopPanel',{attrs:{"back":_vm.back,"backText":"Вернуться на дашборд","pageSwitcher":_vm.pageSwitcher,"pageSwitcherRoutes":[
    { title: 'Отчеты', link: '/sale/dashboard', perm: 'sale_dashboard' },
    { title: 'План компании', icon: 'plan', link: '/sale/plan/progress', perm: 'sale_settings' },
    { title: 'Личный план', icon: 'plan', link: '/sale/plan', perm: 'sale_plan' }
  ],"actions":_vm.actions,"settings":[
    {
      url: '/sale/settings',
      title: 'Настройки модуля',
      enabled: this.can('sale_settings')
    },
    {
      url: '/settings/rights',
      title: 'Настройки прав доступа',
      enabled: this.can('edit_rights')
    }
  ]},on:{"back-click":function($event){return _vm.$emit('back-click')},"refresh-click":function($event){return _vm.$emit('refresh-click')}}})}
var staticRenderFns = []

export { render, staticRenderFns }