<template>
  <TopPanel
    :back="back"
    backText="Вернуться на дашборд"
    :pageSwitcher="pageSwitcher"
    :pageSwitcherRoutes="[
      { title: 'Отчеты', link: '/sale/dashboard', perm: 'sale_dashboard' },
      { title: 'План компании', icon: 'plan', link: '/sale/plan/progress', perm: 'sale_settings' },
      { title: 'Личный план', icon: 'plan', link: '/sale/plan', perm: 'sale_plan' }
    ]"
    :actions="actions"
    @back-click="$emit('back-click')"
    @refresh-click="$emit('refresh-click')"
    :settings="[
      {
        url: '/sale/settings',
        title: 'Настройки модуля',
        enabled: this.can('sale_settings')
      },
      {
        url: '/settings/rights',
        title: 'Настройки прав доступа',
        enabled: this.can('edit_rights')
      }
    ]"
  />
</template>

<script>
import CanMixin from '@/mixins/CanMixin.js'
import TopPanel from '@/components/TopPanel'

export default {
  name: 'SaleTopPanel',
  mixins: [CanMixin],
  components: {
    TopPanel
  },
  props: {
    back: {
      type: Boolean,
      default: false
    },
    backText: {
      type: String,
      default: ''
    },
    actions: {
      type: Boolean,
      default: false
    },
    pageSwitcher: {
      type: Boolean,
      default: false
    },
    pageSwitcherRoutes: {
      type: Array,
      default: () => []
    }
  }
}
</script>
