<template>
  <Block title="Личный план"
    :desc="isChanging ? 'Установите сумму плана которую считаете объективной' : 'В начале каждого месяца руководитель устанавливает план. Вы можете согласиться с ним или изменить сумму если считаете ее невыполнимой'">

    <div class="plan-row">
      <div class="plan-col">
        <div v-if="isStatusEmpty" class="plan-sum">Еще не назначен</div>
        <div v-else class="plan-sum currency">{{ sum | money }}</div>
        <div class="plan-sum-desc rx-text">Рекомендованный план</div>
        <div v-if="min_sum_mode && isChanging" class="plan-sum-min">
          <span class="currency">{{ min_sum | money }}</span><span class="plan-sum-min-desc"> - Минимальное</span>
        </div>
      </div>
      <div v-if="isChanging" class="plan-col plan-col-input">
        <b-form-group label="Желаемая сумма">
          <CurrencyInput v-model="wish_sum" />
        </b-form-group>
      </div>
    </div>

    <div v-if="isStatusEmpty || (isStatusSended && !isChanging)" class="plan-alerts">
      <Alert v-if="isStatusEmpty" icon="warning">Пожалуйста дождитесь, когда руководитель назначит рекомендованный для вас план</Alert>
      <Alert v-if="isStatusSended && !isChanging" icon="warning">Ваша заявка отправлена. Предложенный вами план - <span class="currency">{{ wish_sum | money }}</span>. Дождитесь подтверждения руководителя</Alert>
    </div>

    <div v-if="!isStatusEmpty" class="plan-buttons">
      <button v-if="!isChanging && isStatusConfirm" class="btn btn-primary" @click="accept">Согласен с планом</button>
      <button v-if="!isChanging" class="btn btn-secondary" @click="isChanging = true">Хочу изменить план</button>
      <button v-if="isChanging" class="btn btn-primary" @click="send">Отправить заявку руководителю</button>
      <button v-if="isChanging" class="btn btn-secondary" @click="isChanging = false">Отменить</button>
    </div>
  </Block>
</template>

<script>
import Alert from '@/components/ui/Alert'
import CurrencyInput from '@/components/ui/CurrencyInput'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PersonalBlock',
  components: {
    Alert,
    CurrencyInput
  },
  data () {
    return {
      isLoading: true,
      error: '',
      sum: 0,
      min_sum: 0,
      wish_sum: 0,
      status: '',
      isPlanApproved: false,
      isChanging: false
    }
  },
  computed: {
    ...mapState('sale/settings', ['min_sum_mode']),
    ...mapGetters('sale/date', ['dateQuery']),
    isStatusEmpty () {
      return this.status === 'empty'
    },
    isStatusConfirm () {
      return this.status === 'confirm'
    },
    isStatusSended () {
      return this.status === 'sended'
    },
    isStatusAccepted () {
      return this.status === 'accepted'
    }
  },
  async created () {
    try {
      const result = await this.$api.query(`sale/user-plan/current${this.dateQuery}`)
      this.sum = result.sum
      this.min_sum = result.min_sum
      this.wish_sum = result.wish_sum
      this.status = result.status
    } catch (e) {
      this.error = 'Не удалось получить данные'
    }

    this.isLoading = false
  },
  methods: {
    async accept () {
      await this.$api.put('sale/user-plan/accept')
      this.status = 'accepted'
      this.$router.push({ name: 'sale/plan' })
    },
    async send () {
      await this.$api.put('sale/user-plan/send', { wish_sum: this.wish_sum })
      this.status = 'sended'
      this.isChanging = false
    }
  }
}
</script>

<style scoped>
.plan-row {
  display: flex;
  margin-top: 26px;
}
.plan-col {
  width: 250px;
  margin-right: 60px;
}
.plan-col.plan-col-input {
  width: 200px;
  padding-top: 6px;
}
.plan-col.plan-col-input .col-form-label {
  font-size: 13px;
  line-height: 15px;
  color: var(--text-color);
  margin-bottom: 10px;
  padding-bottom: 0;
}
.plan-sum {
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  color: var(--title-color);
}
.plan-sum-desc {
  margin-top: 10px;
  font-size: 13px;
  line-height: 15px;
}
.plan-sum-min {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: var(--title-color);
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.plan-sum-min-desc {
  font-size: 13px;
  line-height: 15px;
  color: var(--text-color);
}
.plan-alerts {
  margin-top: 35px;
  padding-bottom: 10px;
}
.plan-buttons {
  margin-top: 30px;
}
.plan-buttons button {
  margin-right: 10px;
}
</style>
