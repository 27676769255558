<template>
  <div>
    <TopPanel
      actions
      pageSwitcher
      @refresh-click="refreshUserPlanData()"
    />

    <div v-if="isLoading"><Loader /></div>
    <div v-else>
      <PersonalBlock />
    </div>
  </div>
</template>

<script>
import PersonalBlock from '@/components/sale/PersonalBlock'
import TopPanel from '@/components/sale/TopPanel'
import CanMixin from '@/mixins/CanMixin'

export default {
  name: 'Planning',
  components: {
    PersonalBlock,
    TopPanel
  },
  mixins: [CanMixin],
  data () {
    return {
      isLoading: true
    }
  },
  async created () {
    await this.fetchUserPlanData()
  },
  methods: {
    async refreshUserPlanData () {
      this.$bus.$emit('refreshData')
      await this.fetchUserPlanData()
    },
    async fetchUserPlanData () {
      this.isLoading = true

      const result = await this.$api.query('sale/user-plan/current')
      if (result && result.status === 'accepted') {
        await this.$router.push({ name: 'sale/plan' })
        return
      }

      this.isLoading = false
    }
  }
}
</script>
