<template>
  <b-form-input
    v-model="displayValue"
    :placeholder="placeholder"
    @blur="$emit('blur', format)"
    :state="state"
    @keypress="isNumber"
  />
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: ['value', 'placeholder', 'state'],
  computed: {
    displayValue: {
      get () {
        const formatted = this.$options.filters.money(this.value)
        return this.value !== null ? formatted : ''
      },
      set (modifiedValue) {
        const val = this.format(modifiedValue)
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    format (val = false) {
      if (!val) {
        val = this.displayValue
      }
      let newVal = parseFloat(val.replace(/[^\d.]/g, ''))
      if (isNaN(newVal) || !newVal) {
        newVal = 0
      }
      return newVal
    },
    isNumber (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
